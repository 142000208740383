<template>
	<div>
	<Toolbar class="mb-1">
            <template v-slot:start>
               
                <Button
                  
                  icon="pi pi-plus"
                 
                  @click="open"
                />
            </template>
			<!-- {{productDialog}} -->
			<template v-slot:end>
                
            </template>
			</Toolbar>
			<Dialog header="Edit Vehicle" v-model:visible="display1" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true">
				Select Status : <Dropdown v-model="dropdownValue" :options="dropdownValues" optionLabel="name" placeholder="Select" />
				&nbsp;<Button  label="Update" @click="edit_vehicle(data1)" class="p-button-primary p-button-sm" />
			</Dialog>
			
			<!-- {{dropdownValue}} -->
			<Dialog header="Add Vehicle" v-model:visible="display" :breakpoints="{'960px': '75vw'}" :style="{width: '60vw'}" :modal="true">
					<div
        class="card mb-0"
        style=" font-size: 12px; line-height: 0.9"
      >
      <div class="grid">
        <div class="col-6 md:col-6">
          
      <InputText type="text" v-model="search_number" style="width:100%" placeholder="Enter Registration Number" />
              
        </div>
        <div class="col-6 md:col-6">
            <Button label="Search"  class="p-button-primary p-button-sm" @click="search_vehicle()"/>

        </div>
      </div>
      <!-- {{driver_list}} -->
        <!-- {{data}} -->
        <div class="col-12 md:col-12" v-if="search_loading">
          <img src="https://gifimage.net/wp-content/uploads/2018/11/vehicle-loading-gif-8.gif" style="height:80px;width:100%" />
        </div>
        <br>
        <!-- {{search_status}} -->
        <div class="col-12 md:col-12" v-if="search_status==true">
            No Details Found
        </div>
        <div style="color:white; background-color: #274461; font-size: 12px; line-height: 0.9" class="card mb-0 grid" v-for="(data1,key) in driver_list1" :key="key">
          <div style="text-align: right" class="col-12 md:col-12">
            <span>Status : </span
            ><span v-if="data1.status == 'Active' || data1.status == 'active'"
              ><badge :value="data1.status" severity="success"></badge
            ></span>
            <span
              v-else-if="
                data1.status == 'InActive' || data1.status == 'In Active'
              "
              ><badge :value="data1.status" severity="warning"></badge
            ></span>
            <span
              v-else-if="data1.status == 'Delete' || data1.status == 'Deleted'"
              ><badge :value="data1.status" severity="danger"></badge
            ></span>
            <span v-else>-</span>
            <!-- {{data1}} -->
          </div> 
          
          <div style="text-align: left" class="col-12 md:col-12" >
            
			      <h4>Vehicle Details</h4>
          </div>
          <div class="col-12 md:col-2">
            <img
              style="max-width: 70px; height: 70px; border-radius: 50%"
              :src="
                data1.vehicle_photo
                  ? data1.vehicle_photo
                  : 'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o='
              "
            />
          </div>
          <div class="grid col-12 md:col-10">
          <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Registration No : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <span style="text-align: right"
                  ><b v-if="data1.registration_number">{{ data1.registration_number }}</b> </span
                >
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Vehicle Type : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b>{{ data1.vehicle_type }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>RC Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b>{{ data1.rc_number }}</b>
              </div>
            </div>  
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>PUC Validity : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b>{{ data1.puc_validity }}</b>
              </div>
            </div>
			</div>


          


          <div style="text-align: left" class="col-12 md:col-12" >
            <hr>
			      <h4>Owner Details</h4>
          </div>
		  
          <div class="col-12 md:col-2">
            <img
              style="max-width: 70px; height: 70px; border-radius: 50%"
              :src="
                data1.owner_photo != ''
                  ? data1.owner_photo
                  : 'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o='
              "
            />
          </div>
          <div class="grid col-12 md:col-10">
          <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Owner Name : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <span style="text-align: right"
                  ><b v-if="data1.owner_name">{{ data1.owner_name.toUpperCase() }}</b> </span
                >
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Mobile Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b>{{ data1.owner_mobile_number }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Email id : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b>{{ data1.owner_email_id }}</b>
              </div>
            </div>  
			</div>
			<div style="text-align: left" class="col-12 md:col-12" >
        <hr />
			<h4>Driver Details</h4>
          </div>
		  
          <div class="col-12 md:col-2">
            <img
              style="max-width: 70px; height: 70px; border-radius: 50%"
              :src="
                data1.driver_photo != ''
                  ? data1.driver_photo
                  : 'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o='
              "
            />
          </div>
          <div class="grid col-12 md:col-10">
          <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Driver Name : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <span style="text-align: right"
                  ><b v-if="data1.driver_name">{{ data1.driver_name.toUpperCase() }}</b> </span
                >
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Email id : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b>{{ data1.driver_email_id }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Mobile Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b>{{ data1.driver_mobile_number }}</b>
              </div>
            </div>
            
            <!-- <div class="col-6 md:col-6">
							<span>Model : </span><b>{{data.vehicledetails[0].model}}</b>
						</div> -->
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>DL Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b v-if="data1.dldetails">{{ data1.dldetails.dl_number }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Dl Expiry Date : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b v-if="data1.dldetails">{{ data1.dldetails.dl_expiry_date }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>accidental_history </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b v-if="data1.dldetails">{{ data1.dldetails.accidental_history }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>24/7 Avaibility </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b v-if="data1.dldetails">{{ data1.dldetails.available24by7 }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Shift time from</span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b v-if="data1.dldetails">{{ data1.dldetails.shift_time_from }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Shift time to</span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b v-if="data1.dldetails">{{ data1.dldetails.shift_time_to }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-12">
              <div class="col-6 md:col-6">
                <span>Accidental Discription : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: left">
                <b v-if="data1.dldetails">{{ data1.dldetails.accidental_discription }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-12">
              <div class="col-12 md:col-12" style="text-align:right">
                  <Button  label="Add Vehicle" @click="add_vehicle($event,data1)" class="p-button-primary p-button-sm" />
              </div>
            </div>
            
          </div>
        </div>
        </div>
        
					<template #footer>
						<!-- <Button label="Ok" @click="close" icon="pi pi-check" class="p-button-outlined"/> -->
					</template>
				</Dialog>
				<!-- <Button label="Show" icon="pi pi-external-link" style="width: auto" @click="open"/>	 -->
            
    <div class="" style="height:600px;overflow:scroll">
		
		<div class="col-12 lg:col-6 xl:col-12"  v-for="(data,key) in list" :key="key" >
			<!-- <div  > -->
                <br>
			<div class="card mb-0" style="color:white;background-color:#274461;font-size:12px;line-height:0.9" >
				<!-- {{data}} -->
				<div class="grid">
					<div class="col-10 md:col-10" style="text-align:right">
					</div>
					<div class="col-2 md:col-2" style="text-align:right">
						<!-- <Button
						
							
							icon="pi pi-pencil"
							class="p-button-rounded p-button-success mr-2"
							@click="open1(data)"
						/> -->
					</div>
				</div>
				<div class="grid">
					<div class="col-2 md:col-2">
                       
						<img style="width:70px;height:70px;  border-radius: 50%;" :src="( data.photos!=null? data.photos[0]:'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o=')">
					</div>
					<div class="grid col-4 md:col-10">
						<div class="col-6 md:col-6">
							<span>Owner Name : </span><b>{{data.owner_name}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Driver Name : </span><b>{{data.driver_name}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Status : </span><b>{{data.status}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Registration Number : </span><span style="text-align:right"><b>{{data.registration_number}}</b></span>
						</div>
							<div class="col-6 md:col-6">
							<span>Owner Mobile Number : </span><b>{{data.owner_mobile_number}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Driver Mobile Number : </span><b>{{data.driver_mobile_number}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>RC Number : </span><b>{{data.rc_number}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Model : </span><b>{{data.model}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Fuel : </span><b>{{data.fuel_type}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Make Year : </span><b>{{data.make_year}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Fitness Validity : </span><b>{{D_M_Y_formatDate(data.fitness_validity)}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>PUC Validity : </span><b>{{D_M_Y_formatDate(data.puc_validity)}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Insurance Validity : </span><b>{{D_M_Y_formatDate(data.insurance_validity)}}</b>
						</div>

					</div>
						
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="">since last visit</span> -->
			</div>
			<!-- </div> -->
		
		</div>
		
		<div class="col-12" v-if="list==''" style="text-align:left">
            <br>	
			Data Not Found
		</div>
    </div>
</div>
</template>

<script>

import ProductService from "../../service/ProductService";
import { FilterMatchMode } from "primevue/api";
import apis from "@/apis";
export default {
props: ['department_id','department_client_id'],
data() {
    return {
		isLoading: false,
		display: false,
		dropdownValue:'',
		dropdownValues: [
					{name: 'Active', code: 'Active'},
					{name: 'In Active', code: 'In Active'}
					// {name: 'London', code: 'LDN'}
		],
				
      isLoadingModel: false,
      loading: false,
      fullPage: true,
	  data1:[],
	  list:[],
	  search_status:false,
	  search_loading:false,

      productDialog: false,
	  search_number:'',
	  display3:false,
	  driver_list1:[],
      display1:false,
	  id:'',
	}
},
methods:{
	getDepartmentVehicle_list()
    {
          this.isLoadingModel=true;

     
      var data={
                    "department_id" : this.department_id,
                    "client_id" : localStorage.getItem('client_id')
                    // "department_id" : "641be77dc31a1828a6eaead8",
                    // "client_id" : "641be62bc31a1828a6eaead5"
            };
      var promise = apis.departmentVehicleListget(data);
      promise
        .then(response => {
          
          this.isLoadingModel=false;
            this.list=response.data.data;
          }
        )
    },
      
  
	open() {
				this.display = true;
			},
	
	open1(data) {
		this.dropdownValue={name:data.status,code:data.status}
		this.id=data._id;
				this.display1 = true;
			},
	close() {
				this.display = false;
			},
	add_vehicle(event,data) {
      var details={
                    "client_id":this.department_client_id,
                    "vehicle_id":data._id,
                    "department_id":this.department_id,
                    "user_id":localStorage.getItem('id')
                
                }
	        this.search_loading=true;
      var promise = apis.mappedVehcielDepartment(details);
      promise
        .then(response => {
          this.search_loading=false;
			if(response.data.status==true)
			{
				alert(response.data.message);
				this.display=false;
				this.search_number='';
				this.getDepartmentVehicle_list();
			}
			else
			{
				alert(response.data.data)

			}
          }
        )
	  console.log(data);
    },
	// edit_vehicle(data) {
    //   var data={id:this.id,vehicle_id:data._id,status:this.dropdownValue.name}
	//     this.search_loading=true;
    //   var promise = apis.unmappedVehcielGroup(data);
    //   promise
    //     .then(response => {
    //       this.search_loading=false;
	// 		if(response.data.status==true)
	// 		{
	// 			alert(response.data.message);
	// 			this.display1=false;
	// 			this.search_number='';
	// 			this.getDepartmentVehicle_list();
	// 		}
	// 		else
	// 		{
	// 			alert(response.data.data)

	// 		}
    //       }
    //     )
	//   console.log(data);
    // },
	search_vehicle()
    {
        var data={
            registration_number:this.search_number,
            client_id:this.department_client_id
            };
            this.search_loading=true;
      var promise = apis.searchGroupVehicleAdd(data);
      promise
        .then(response => {
          this.search_loading=false;
            this.driver_list1=response.data.data;
            // alert(search_result.length);
            if(this.driver_list1.length==0)
            {
                this.search_status=true;
            }
            else{
                this.search_status=false;
            }
          }
        )
    },
	openNew() {
	alert("here");
    //   this.reset_details();
    //   this.product = {};
    //   this.products = [];
    //   this.submitted = false;
      this.productDialog = true;
    },
	D_M_Y_formatDate(date_parm)
    {
      if(date_parm)
      {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd+"-"+mm + "-" + yyyy ;
        // alert(dd)
        if(dd.toString()!="NaN")
        {
          //this.receipt_new_date = new_date;
          return new_date;
        }
        else
        {
          return date_parm;
        }
      }
    },
},
mounted(){
	this.getDepartmentVehicle_list();
	// alert('here');
}
}
</script>