<template>
    <div class="grid p-fluid">
      <!-- {{departmentdata}} -->
      <div class="col-12 md:col-12"><Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" /></div>
      <div class="col-12 md:col-4">
        <div class="card card-w-title" style="height: 800px">
          <div style="col-12 md;text-align:center">
            <img v-if="departmentdata.profile_image"
              :src="departmentdata.profile_image"
              style="width: 200px;height:200px; border-radius: 20%"
            />
            <img v-else
              src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o="
              style="width: 200px; border-radius: 20%"
            />
            <hr />
          </div>
          <div style="">
            <div class="grid">
              <!-- <div class="row"> -->
              <div class="col-4 md:col-4"><b>Name</b> </div>
              <div class="col-8 md:col-8">{{departmentdata.name}}</div>
              <div class="col-4 md:col-4"><b>status</b></div>
              <div class="col-8 md:col-8">{{departmentdata.status}}</div>
              <div class="col-4 md:col-4"><b>City</b></div>
              <div class="col-8 md:col-8">{{departmentdata.city}}</div>
               <div class="col-4 md:col-4"><b>State</b></div>
              <div class="col-8 md:col-8">{{departmentdata.state}}</div>
             
              <div class="col-4 md:col-4"><b>Pincode</b></div>
              <div class="col-8 md:col-8">{{departmentdata.pincode}}</div>
              <div class="col-4 md:col-4"><b>Description</b></div>
              <div class="col-8 md:col-8" v-if="departmentdata.description">{{departmentdata.description}}</div>
              <div class="col-8 md:col-8" v-else></div>
  
              <!-- </div> -->
            </div>
            
          </div>
        </div>
      </div>
      <div class="col-12 md:col-8">
        <div class="card card-w-title" style="height: 800px">
    
            <tabs
      v-model="selectedTab"
    >
    <!-- {{tabs}} -->
      <tab
        class="tab"
        v-for="(tab, i) in tabs"
        :key="`t${i}`"
        :val="tab"
        :label="tab"
        :indicator="true"
      />
    </tabs>
      <tab-panels
      v-model="selectedTab"
      :animate="true"
    >
      <tab-panel >
   
        <loading
              :active="isLoadingModel"
              :can-cancel="true"
              
              color="#274461"
              :is-full-page="fullPage"
            ></loading>
         
           
        <DepartmentGroup      :departmentdata="departmentdata" v-if="selectedTab=='Route' && departmentdata_id"></DepartmentGroup>
        <DepartmentMapVehicle   :department_client_id="department_client_id" :department_id="departmentdata_id"  v-if="selectedTab=='Vehicle' && departmentdata_id && department_client_id"></DepartmentMapVehicle>
       
        
      </tab-panel>
    </tab-panels>
  
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';
  import DepartmentGroup from '../components/menu/DepartmentGroup'
  import DepartmentMapVehicle from '../components/menu/Department_Map_Vehicle'
  import BreadcrumbService from "../service/BreadcrumbService";

  

  import apis from "@/apis";
  
  
  export default {
    data() {
      return {
          active:"active",
          tabs : ['Route','Vehicle'],
          selectedTab: "Route",
        //userType:"Rider",
           departmentdata:[],
           departmentdata_id:'',
           department_client_id:'',
        //   group_type:"",
        //   groupUserList:[], 
        //   groupVehicleList:[], 
          isLoadingModel:false,
          BreadcrumbService : null,
breadcrumbHome: {},
breadcrumbItems: [],
      };
    },
    async mounted(){
      
      if(localStorage.getItem('savedepartmentdata'))
      {
         this.departmentdata=await JSON.parse(localStorage.getItem('savedepartmentdata'));
          this.departmentdata_id= await this.departmentdata._id;
          this.department_client_id= await  this.departmentdata.client_id;
        //  this.group_type= await this.departmentdata.group_type;
      
      }
      
      
    },
    async created(){
      this.BreadcrumbService = new BreadcrumbService();
	let Breadcrum_data = this.BreadcrumbService.DepartmentProfile()
	this.breadcrumbHome=Breadcrum_data.home;
	this.breadcrumbItems=Breadcrum_data.items;
    },
    methods: {

      
    
      D_M_Y_formatDate(date_parm)
      {
        if(date_parm)
        {
          let date = new Date(date_parm);
          let dd = date.getDate();
          let mm = date.getMonth() + 1;
          let yyyy = date.getFullYear();
          let new_date = dd+"-"+mm + "-" + yyyy ;
          // alert(dd)
          if(dd.toString()!="NaN")
          {
            //this.receipt_new_date = new_date;
            return new_date;
          }
          else
          {
            return date_parm;
          }
        }
      },
      toggleMenu(event) {
        this.$refs.menu.toggle(event);
      },
      onContextRightClick(event) {
        this.$refs.contextMenu.show(event);
      },
    },
    components:{
          Tabs,
      Tab,
      TabPanels,
      TabPanel,
      DepartmentGroup,
      DepartmentMapVehicle
  
      
    }
  };
  </script>